.Swagger .info {
  display: none;
}

.Swagger-non-interactive .scheme-container {
  display: none;
}
.Swagger-non-interactive .try-out {
  display: none;
}

.Swagger-hide-tags .opblock-tag {
  display: none;
}

section.models {
  display: none;
}
